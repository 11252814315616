<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 20.6542V11.4084L11.6896 3L20.4794 11.4084V20.6542H3Z" stroke="#2AA65C" stroke-width="2" stroke-linejoin="round"/>
    <path d="M8.92603 20.6542V14.0226H14.6102V20.6542" stroke="#FCB016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "HomeIcon"
}
</script>

<style scoped>

</style>
