<template>
  <svg width="73" height="74" viewBox="0 0 73 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M36.5 71C55.2777 71 70.5 55.7777 70.5 37C70.5 18.2223 55.2777 3 36.5 3C17.7223 3 2.5 18.2223 2.5 37C2.5 55.7777 17.7223 71 36.5 71Z" stroke="#FF0000" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.5 25L48.5 49" stroke="#FF0000" stroke-width="5" stroke-linecap="round"/>
    <path d="M48.5 25L24.5 49" stroke="#FF0000" stroke-width="5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "FailIcon"
}
</script>

<style scoped>

</style>
