<template>
  <svg width="12" height="20" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.90723 15.1538C7.13135 15.3696 7.39697 15.4858 7.7124 15.4858C8.35156 15.4858 8.87451 14.9712 8.87451 14.332C8.87451 14.0166 8.7417 13.7178 8.50928 13.4937L2.86475 7.99854L8.50928 2.52002C8.7417 2.2876 8.87451 1.98877 8.87451 1.68164C8.87451 1.04248 8.35156 0.527832 7.7124 0.527832C7.38867 0.527832 7.12305 0.635742 6.90723 0.851562L0.540527 7.07715C0.258301 7.35107 0.117188 7.6499 0.117188 8.00684C0.117188 8.35547 0.25 8.6543 0.540527 8.93652L6.90723 15.1538Z" fill="#081221"/>
  </svg>
</template>

<script>
export default {
  name: "BackArrow"
}
</script>

<style scoped>

</style>
