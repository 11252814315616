<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6341 4.53599C17.6651 2.56474 15.0467 1.47868 12.2569 1.47754C6.50864 1.47754 1.83018 6.15562 1.82794 11.9056C1.82713 13.7437 2.30736 15.5378 3.22003 17.1193L1.74048 22.5235L7.26899 21.0733C8.79223 21.9041 10.5073 22.342 12.2527 22.3426H12.257C12.2573 22.3426 12.2567 22.3426 12.257 22.3426C18.0047 22.3426 22.6834 17.6641 22.6858 11.914C22.6869 9.12733 21.6031 6.50715 19.6341 4.53599ZM12.2569 20.5814H12.2534C10.6981 20.5808 9.17251 20.1628 7.84163 19.3731L7.52515 19.1853L4.24445 20.0458L5.12015 16.8472L4.91403 16.5192C4.04637 15.1392 3.58811 13.544 3.58879 11.9062C3.59065 7.12704 7.47922 3.2389 12.2604 3.2389C14.5756 3.23962 16.752 4.14246 18.3886 5.78088C20.0251 7.41931 20.9259 9.59714 20.9249 11.9132C20.923 16.6928 17.0346 20.5814 12.2569 20.5814Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0115 14.0896C16.751 13.9592 15.4698 13.3289 15.231 13.2419C14.9921 13.1549 14.8184 13.1115 14.6447 13.3723C14.471 13.6331 13.9716 14.2201 13.8196 14.3941C13.6676 14.5679 13.5155 14.5898 13.255 14.4592C12.9944 14.3288 12.1548 14.0537 11.1595 13.1659C10.3848 12.4749 9.86187 11.6216 9.70981 11.3607C9.55783 11.0999 9.69364 10.9589 9.82411 10.8289C9.94133 10.7121 10.0847 10.5246 10.215 10.3724C10.3453 10.2203 10.3887 10.1115 10.4756 9.93769C10.5624 9.76374 10.519 9.61155 10.4539 9.48117C10.3887 9.35074 9.86758 8.06812 9.65046 7.54628C9.43887 7.03824 9.22408 7.10707 9.0641 7.09899C8.91233 7.09141 8.73839 7.08984 8.56469 7.08984C8.391 7.08984 8.10864 7.15508 7.86983 7.41589C7.63099 7.67679 6.95776 8.30726 6.95776 9.58975C6.95776 10.8724 7.89151 12.1115 8.02181 12.2854C8.15207 12.4594 9.85929 15.0913 12.4733 16.22C13.095 16.4885 13.5804 16.6489 13.9588 16.7689C14.583 16.9673 15.1511 16.9393 15.6001 16.8722C16.1008 16.7974 17.1418 16.2418 17.359 15.6332C17.5761 15.0245 17.5761 14.5027 17.5109 14.394C17.4458 14.2854 17.2721 14.2201 17.0115 14.0896Z" fill="#FCB016"/>
  </svg>
</template>

<script>
export default {
  name: "WhatsappIcon"
}
</script>

<style scoped>

</style>