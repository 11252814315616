<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#F1F2F6"/>
    <path d="M15.5999 8.40002L8.3999 15.6" stroke="#9AA3B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.3999 8.40002L15.5999 15.6" stroke="#9AA3B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CloseModalIcon"
}
</script>

<style scoped>

</style>