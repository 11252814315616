<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5439 17.5H6.95607C6.40015 17.5 5.92152 17.8924 5.8125 18.4375L5.76505 18.6748C5.61093 19.4453 4.93435 20 4.14853 20C3.23807 20 2.5 19.2619 2.5 18.3515V14.4614C2.5 13.8361 2.69537 13.2265 3.0588 12.7177L4.84123 10.2223C4.94659 10.0748 5.0314 9.91364 5.09335 9.74329L6.2819 6.47477C6.71302 5.2892 7.83976 4.5 9.10128 4.5H14.5683C15.7447 4.5 16.8125 5.18761 17.2994 6.25859L18.9196 9.82318C18.9731 9.94078 19.0378 10.0529 19.1129 10.1581L20.9412 12.7177C21.3046 13.2265 21.5 13.8361 21.5 14.4614V18.0767C21.5 19.1389 20.6389 20 19.5767 20C18.6599 20 17.8685 19.3427 17.6887 18.4437C17.5797 17.8986 17.0998 17.5 16.5439 17.5Z" stroke="#2AA65C" stroke-width="2" stroke-linejoin="round"/>
    <path d="M18 14L16 14.25V15H18V14Z" stroke="#FCB016" stroke-opacity="0.5" stroke-width="2" stroke-linejoin="round"/>
    <path d="M6 14L8 14.25V15H6V14Z" stroke="#FCB016" stroke-opacity="0.5" stroke-width="2" stroke-linejoin="round"/>
    <path d="M19 10H21V11H19.3333" stroke="#2AA65C" stroke-width="2" stroke-linejoin="round"/>
    <path d="M5 10H3V11H4.66667" stroke="#2AA65C" stroke-width="2" stroke-linejoin="round"/>
    <path d="M8 10L16 10" stroke="#FCB016" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "Car"
}
</script>

<style scoped>

</style>
