<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.09213 19.8913L1 17.163L3.04607 15.1168H5.77415L9.18426 11.7064L1 6.24977L3.04607 4.20354L13.2764 7.61393L18.0599 2.84876C19.1895 1.71736 21.0225 1.71736 22.1521 2.84706C23.2825 3.9759 23.2825 5.80898 22.1529 6.93867L17.3881 11.7226L20.7982 21.9538L18.7522 24L13.296 15.8151L9.88589 19.2255L9.86628 21.9342L7.82022 23.9804L5.09213 19.8913Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5 1.75C12.5 0.783503 11.7165 1.23927e-07 10.75 1.64662e-07C9.7835 2.05396e-07 9 0.783504 9 1.75C9 2.7165 9.7835 3.5 10.75 3.5C11.7165 3.5 12.5 2.7165 12.5 1.75Z" fill="#FCB016"/>
  </svg>
</template>

<script>
export default {
  name: "UmbrellaIcon"
}
</script>

<style scoped>

</style>
