<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 17C8 16.4477 7.55228 16 7 16H2V2H7C7.55228 2 8 1.55228 8 1C8 0.447714 7.55228 0 7 0H2C0.895431 0 0 0.895431 0 2V16C0 17.1046 0.895431 18 2 18H7C7.55228 18 8 17.5523 8 17Z" fill="#2AA65C"/>
    <path d="M18.7136 9.70055C18.8063 9.6062 18.8764 9.49805 18.9241 9.38278C18.9727 9.26575 18.9996 9.1375 19 9.003V9V8.997C18.9992 8.74208 18.9016 8.48739 18.7071 8.29289L14.7071 4.29289C14.3166 3.90237 13.6834 3.90237 13.2929 4.29289C12.9024 4.68342 12.9024 5.31658 13.2929 5.70711L15.5858 8H6C5.44771 8 5 8.44771 5 9C5 9.55229 5.44771 10 6 10H15.5858L13.2929 12.2929C12.9024 12.6834 12.9024 13.3166 13.2929 13.7071C13.6834 14.0976 14.3166 14.0976 14.7071 13.7071L18.7064 9.70782L18.7136 9.70055Z" fill="#FDD78B"/>
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon"
}
</script>

<style scoped>

</style>
