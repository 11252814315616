<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.00024 4H20.0002C21.1002 4 22.0002 4.9 22.0002 6V18C22.0002 19.1 21.1002 20 20.0002 20H4.00024C2.90024 20 2.00024 19.1 2.00024 18V6C2.00024 4.9 2.90024 4 4.00024 4Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.0002 6L12.0002 12L2.00024 6" stroke="#FCB016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "MessageIcon"
}
</script>

<style scoped>

</style>