<template>
  <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2141 15.6826H17.7856V2.31738H10.2141V15.6826Z" fill="#FF5F00"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.695 8.99998C10.695 6.28848 11.9874 3.87378 14 2.31734C12.528 1.17905 10.671 0.5 8.65238 0.5C3.87371 0.5 0 4.30563 0 8.99998C0 13.6943 3.87371 17.5 8.65238 17.5C10.671 17.5 12.528 16.8209 14 15.6826C11.9874 14.1262 10.695 11.7115 10.695 8.99998Z" fill="#EB001B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7298 14.2671V13.9373H27.6421L27.5414 14.1642L27.4404 13.9373H27.3527V14.2671H27.4145V14.0183L27.5092 14.2329H27.5734L27.6681 14.0178V14.2671H27.7298ZM27.1743 14.2671V13.9935H27.2868V13.9378H27.0008V13.9935H27.1131V14.2671H27.1743ZM28 8.99998C28 13.6943 24.1261 17.5 19.3476 17.5C17.329 17.5 15.4717 16.8209 14 15.6826C16.0126 14.1264 17.305 11.7115 17.305 8.99998C17.305 6.28848 16.0126 3.87378 14 2.31734C15.4717 1.17905 17.329 0.5 19.3476 0.5C24.1261 0.5 28 4.30563 28 8.99998Z" fill="#F79E1B"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="17" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "MasterCardIcon"
}
</script>

<style scoped>

</style>