<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 16H16H32L18.8284 29.1716C17.2663 30.7337 14.7337 30.7337 13.1716 29.1716L0 16Z" fill="white"/>
    <path
        d="M16.5 11C16.5 10.7239 16.2761 10.5 16 10.5C15.7239 10.5 15.5 10.7239 15.5 11H16.5ZM15.6464 20.3536C15.8417 20.5488 16.1583 20.5488 16.3536 20.3536L19.5355 17.1716C19.7308 16.9763 19.7308 16.6597 19.5355 16.4645C19.3403 16.2692 19.0237 16.2692 18.8284 16.4645L16 19.2929L13.1716 16.4645C12.9763 16.2692 12.6597 16.2692 12.4645 16.4645C12.2692 16.6597 12.2692 16.9763 12.4645 17.1716L15.6464 20.3536ZM15.5 11V20H16.5V11H15.5Z"
        fill="#2AA65C"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowDown"
}
</script>

<style scoped>

</style>