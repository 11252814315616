<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" fill="white"/>
    <path opacity="0.5" d="M22 31.5H32" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7698 12.7258C28.3958 12.0998 29.3082 11.8554 30.1633 12.0845C31.0184 12.3136 31.6864 12.9816 31.9155 13.8367C32.1446 14.6918 31.9002 15.6042 31.2742 16.2302L16.6725 30.8319L12 32L13.1681 27.3275L27.7698 12.7258Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="#F1F2F6"/>
  </svg>


</template>

<script>
export default {
  name: "ChangePolicy"
}
</script>