import axios from 'axios';

export async function getPeopleByIin (iin) {
    const {data} = await window.axios.get(`/info/get-people-by-iin?iin=${iin}`);
    return data;
}
export async function getCarByLicensePlate (number) {
    const {data} = await window.axios.get(`/info/get-car-by-license-plate?license_plate=${number}`);
    return data;
}
export async function getAnnualRate (aup) {
    const {data} = await axios.get(`https://products.halyklife.kz/api/v1/policy/${aup}?authorization=f5206857-0f2e-4248-b3ec-062c96156c90`);
    return data.annual_effective_rate;
}
