<template>
  <modal-layout @close="$emit('close')">
    <div class="p-3">
      <strong v-if="rating<3" class="primary-text">{{$t('lowFeedback')}}</strong>
      <strong v-if="rating===3" class="primary-text">{{$t('mediumFeedback')}}</strong>
      <strong v-if="rating>3" class="primary-text">{{$t('highFeedback')}}</strong>
    </div>
    <button class="primary-btn bottom-page-btn" @click="$emit('close')">{{$t('close')}}</button>
  </modal-layout>
</template>

<script>
export default {
  name: "FeedbackResultModal",
  props: {
    rating: null
  }
}
</script>

<style scoped>
.primary-text {
  font-size: 20px;
}
</style>
