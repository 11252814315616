<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" fill="white"/>
    <path d="M22 35C29.1797 35 35 29.1797 35 22C35 14.8203 29.1797 9 22 9C14.8203 9 9 14.8203 9 22C9 29.1797 14.8203 35 22 35Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M13 13L31 31" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="#F1F2F6"/>
  </svg>
</template>

<script>
export default {
  name: "Stop"
}
</script>