<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2.4097L10.9997 2.40999L10.7392 2.11123C8.28507 -0.70244 4.24215 -0.702439 1.78806 2.11123C-0.596019 4.84461 -0.596019 9.22699 1.78806 11.9604L2.80226 13.1232L10.2461 21.6577C10.436 21.8754 10.7108 22.0004 10.9997 22.0004C10.9998 22.0004 10.9999 22.0004 11 22.0004V19.4788L10.9997 19.479L4.3095 11.8086L3.2953 10.6458C1.56823 8.66564 1.56823 5.40595 3.2953 3.42585C4.95234 1.52602 7.57488 1.52602 9.23192 3.42585L10.2461 4.58865C10.436 4.80638 10.7108 4.93134 10.9997 4.93134C10.9998 4.93134 10.9999 4.93134 11 4.93134V2.4097Z" fill="#FCB016"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2.40872L11.0003 2.40901L11.2608 2.11025C13.7149 -0.703417 17.7578 -0.703416 20.2119 2.11025C22.596 4.84364 22.596 9.22601 20.2119 11.9594L19.1977 13.1222L11.7539 21.6567C11.564 21.8745 11.2892 21.9994 11.0003 21.9994C11.0002 21.9994 11.0001 21.9994 11 21.9994V19.4778L11.0003 19.4781L17.6905 11.8076L18.7047 10.6448C20.4318 8.66467 20.4318 5.40498 18.7047 3.42487C17.0477 1.52504 14.4251 1.52504 12.7681 3.42487L11.7539 4.58767C11.564 4.80541 11.2892 4.93036 11.0003 4.93036C11.0002 4.93036 11.0001 4.93036 11 4.93036V2.40872Z" fill="#2AA65C"/>
  </svg>
</template>

<script>
export default {
  name: "HeartIcon"
}
</script>

<style scoped>

</style>