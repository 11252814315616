<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4375 4.125L12.4375 19.875H11.5625L11.5625 4.125L12.4375 4.125Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.152 19.4641C12.1517 19.464 12.1515 19.4639 12.0017 19.875C11.8519 20.2861 11.8516 20.2859 11.8513 20.2858L11.8488 20.2849L11.8443 20.2832L11.8312 20.2782C11.8206 20.274 11.8064 20.2683 11.7889 20.261C11.7539 20.2463 11.7054 20.2248 11.6455 20.1958C11.5257 20.1378 11.3597 20.0491 11.1631 19.9224C10.7697 19.6689 10.2534 19.2626 9.73989 18.6439C8.70716 17.3997 7.71388 15.33 7.71387 12C7.71385 8.66999 8.70713 6.60032 9.73987 5.35606C10.2533 4.73742 10.7697 4.33111 11.1631 4.0776C11.3597 3.95087 11.5257 3.86223 11.6455 3.80419C11.7054 3.77516 11.7539 3.75375 11.7889 3.73902C11.8064 3.73166 11.8206 3.72597 11.8312 3.72183L11.8443 3.71676L11.8488 3.71508L11.8505 3.71444C11.8509 3.71432 11.8519 3.71394 12.0017 4.125C12.1515 4.53606 12.1517 4.53596 12.152 4.53586L12.1501 4.53662C12.1461 4.53818 12.1387 4.54115 12.128 4.54565C12.1066 4.55463 12.0723 4.56968 12.027 4.59166C11.9362 4.63562 11.8014 4.70715 11.6371 4.81307C11.3085 5.02486 10.8623 5.37379 10.4132 5.91489C9.52074 6.9901 8.58885 8.85793 8.58887 12C8.58888 15.1421 9.52077 17.0099 10.4132 18.0851C10.8623 18.6262 11.3085 18.9751 11.6371 19.1869C11.8014 19.2929 11.9362 19.3644 12.027 19.4083C12.0723 19.4303 12.1066 19.4454 12.128 19.4544C12.1387 19.4588 12.1461 19.4618 12.1501 19.4634L12.152 19.4641Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.848 19.4641L11.8499 19.4634C11.8539 19.4618 11.8613 19.4588 11.872 19.4544C11.8934 19.4454 11.9277 19.4303 11.973 19.4083C12.0638 19.3644 12.1986 19.2929 12.3629 19.1869C12.6915 18.9751 13.1377 18.6262 13.5868 18.0851C14.4792 17.0099 15.4111 15.1421 15.4111 12C15.4111 11.7584 15.607 11.5625 15.8486 11.5625C16.0903 11.5625 16.2861 11.7584 16.2861 12C16.2861 15.33 15.2928 17.3997 14.2601 18.6439C13.7466 19.2626 13.2303 19.6689 12.8369 19.9224C12.6403 20.0491 12.4743 20.1378 12.3545 20.1958C12.2946 20.2248 12.2461 20.2463 12.2111 20.261C12.1936 20.2683 12.1794 20.274 12.1688 20.2782L12.1557 20.2832L12.1512 20.2849L12.1495 20.2856C12.1491 20.2857 12.1481 20.2861 11.9983 19.875L12.1481 20.2861C11.9211 20.3688 11.67 20.2518 11.5873 20.0248C11.5048 19.7984 11.6209 19.5481 11.8466 19.4646" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.125 11.5625L19.875 11.5625V12.4375L4.125 12.4375L4.125 11.5625Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.48314 6.89251C8.40453 7.34774 9.86944 7.81745 12 7.81745V8.69245C9.73547 8.69245 8.13789 8.19196 7.09555 7.67699C6.57542 7.42001 6.19621 7.16074 5.94319 6.96136C5.81672 6.8617 5.72184 6.77706 5.65669 6.7152C5.62411 6.68427 5.59896 6.65904 5.581 6.64046C5.57202 6.63118 5.56483 6.62355 5.55942 6.61772L5.55261 6.61031L5.5502 6.60765L5.54923 6.60657C5.54904 6.60636 5.54844 6.60568 5.875 6.31454C6.20156 6.0234 6.20139 6.02321 6.20122 6.02302L6.20044 6.02214C6.20031 6.022 6.20053 6.02227 6.20122 6.02302L6.21 6.0322C6.21953 6.04205 6.23583 6.05851 6.25914 6.08063C6.30576 6.12489 6.38037 6.19184 6.48476 6.2741C6.69344 6.43855 7.0214 6.66438 7.48314 6.89251Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7988 17.977C17.7986 17.9768 17.7984 17.9766 18.125 17.6855C18.4516 17.3943 18.4514 17.3941 18.4512 17.3939L18.4498 17.3924L18.4474 17.3897L18.4406 17.3823C18.4352 17.3764 18.428 17.3688 18.419 17.3595C18.401 17.341 18.3759 17.3157 18.3433 17.2848C18.2782 17.2229 18.1833 17.1383 18.0568 17.0386C17.8038 16.8393 17.4246 16.58 16.9044 16.323C15.8621 15.808 14.2645 15.3076 12 15.3076C9.73547 15.3075 8.13789 15.808 7.09555 16.323C6.57542 16.58 6.19621 16.8393 5.94319 17.0386C5.81672 17.1383 5.72184 17.2229 5.65669 17.2848C5.62411 17.3157 5.59896 17.341 5.581 17.3595C5.57202 17.3688 5.56483 17.3764 5.55942 17.3823L5.55261 17.3897L5.5502 17.3924L5.54923 17.3934C5.54904 17.3936 5.54844 17.3943 5.875 17.6855C6.20156 17.9766 6.20139 17.9768 6.20122 17.977L6.20044 17.9779L6.21 17.9678C6.21953 17.9579 6.23583 17.9415 6.25914 17.9194C6.30576 17.8751 6.38037 17.8082 6.48476 17.7259C6.69344 17.5615 7.0214 17.3356 7.48314 17.1075C8.40453 16.6523 9.86944 16.1825 12 16.1826C14.1306 16.1826 15.5955 16.6523 16.5169 17.1075C16.9786 17.3356 17.3066 17.5615 17.5152 17.7259C17.6196 17.8082 17.6942 17.8751 17.7409 17.9194C17.7642 17.9415 17.7805 17.9579 17.79 17.9678L17.7988 17.977ZM17.7996 17.9779C17.7997 17.978 17.7995 17.9777 17.7988 17.977L17.7996 17.9779Z" fill="#2AA65C"/>
    <path d="M15.5 10.25C16.4665 10.25 17.25 9.4665 17.25 8.5C17.25 7.5335 16.4665 6.75 15.5 6.75C14.5335 6.75 13.75 7.5335 13.75 8.5C13.75 9.4665 14.5335 10.25 15.5 10.25Z" fill="#FCB016"/>
  </svg>
</template>

<script>
export default {
  name: "LangIcon"
}
</script>

<style scoped>

</style>
