<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.37243 0.461426C4.67718 0.461426 2.48438 2.65423 2.48438 5.34948C2.48438 8.73713 7.37724 14.5239 7.37724 14.5239C7.37724 14.5239 12.2605 8.57054 12.2605 5.34948C12.2605 2.65423 10.0678 0.461426 7.37243 0.461426ZM8.84726 6.78071C8.4406 7.18729 7.90656 7.39062 7.37243 7.39062C6.83839 7.39062 6.30418 7.18729 5.89769 6.78071C5.08444 5.96755 5.08444 4.64438 5.89769 3.83114C6.29148 3.43718 6.81531 3.2202 7.37243 3.2202C7.92956 3.2202 8.4533 3.43726 8.84726 3.83114C9.66051 4.64438 9.66051 5.96755 8.84726 6.78071Z" fill="#2AA65C"/>
  </svg>
</template>

<script>
export default {
  name: "LocationIcon"
}
</script>

<style scoped>

</style>