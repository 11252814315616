<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
    <path d="M5.9915 24.0002V16.8571H13.9328V24.0002H17.0575V8.93394H13.9328V14.1958H5.9915V8.93394H2.83865V24.0002H5.9915ZM17.0575 5.45371V1.58618H13.9328V3.04135L17.0575 5.45371Z" fill="url(#paint0_linear_0_44)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.32036 7.71293L10 3.36957L15.6796 7.71293H20L10 0L0 7.71293H4.32036Z" fill="url(#paint1_linear_0_44)"/>
    <defs>
      <linearGradient id="paint0_linear_0_44" x1="88.437" y1="1.58618" x2="90.6134" y2="24.3015" gradientUnits="userSpaceOnUse">
        <stop stop-color="#32AA55"/>
        <stop offset="1" stop-color="#00735A"/>
      </linearGradient>
      <linearGradient id="paint1_linear_0_44" x1="9.23638" y1="0.323849" x2="19.0816" y2="7.89461" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB92D"/>
        <stop offset="0.658227" stop-color="#F5A232"/>
        <stop offset="1" stop-color="#F58C32"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "HomeBankIcon"
}
</script>

<style scoped>

</style>
