<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 16C19.1046 16 20 15.1046 20 14C20 12.8954 19.1046 12 18 12C16.8954 12 16 12.8954 16 14C16 15.1046 16.8954 16 18 16Z" fill="#FCB016"/>
    <path d="M23 10.2346V17.7346C23 19.3915 21.6569 20.7346 20 20.7346H4C2.34315 20.7346 1 19.3915 1 17.7346V10.2346C1 8.57776 2.34315 7.23462 4 7.23462H20C21.6569 7.23462 23 8.57776 23 10.2346Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 7.23462V6.23462C21 4.57776 19.6569 3.23462 18 3.23462H4C2.34315 3.23462 1 4.57776 1 6.23462V11.2346" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "WalletIcon"
}
</script>

<style scoped>

</style>
