window.axios = require("axios");
window.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL + '/api';

window.axios.defaults.headers.common = {
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Language': localStorage.getItem('lang') || 'ru'
    // crossDomain: true,
};

Array.prototype.swap = function (x,y) {
  var b = this[x];
  this[x] = this[y];
  this[y] = b;
  return this;
}