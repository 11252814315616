<template>
  <section class="navigation container">
    <div class="nav-inner">
      <a
          :href="item.link"
          class="nav-inner__link"
          v-for="(item, index) in navigation"
          :key="index"
      >
        <icon :icon-name="isExactActive(item.baseLink) ? item.activeIcon: item.icon" class="icon"></icon>
        <br>{{ item.name }}
      </a>
      <a href="#" @click="homebankLinkAction" class="nav-inner__link" >
        <icon icon-name="home-bank-icon" class="icon"></icon>
        <br> <span style="color:#2AA65C">HALYK</span>
      </a>
    </div>
  </section>
</template>

<script>
import eventLogger from '../services/eventLogger'
import {mapGetters} from "vuex";
export default {
  name: "TheNavigation",
  data() {
    return {
    }
  },
  methods: {
    homebankLinkAction() {
      eventLogger('select_homebank_tab')

      if (this.$store.getters.os?.toLowerCase() === 'ios') {
        window.webkit?.messageHandlers?.close?.postMessage('')
        window.webkit?.messageHandlers?.close?.postMessage('')
        window.webkit?.messageHandlers?.close?.postMessage('')
      } else {
        window.location = 'https://homebank.kz/';
      }
    }
  },
  computed: {
    ...mapGetters({
      authStatus: 'status'
    }),
    isExactActive(){
        return baseLink => baseLink.href === this.$route.path
    },
    navigation() {
      return [
        {name: this.$i18n.t('main'), icon: 'home-icon-one', activeIcon: 'active-home-icon', link: this.welcomeLink, baseLink: this.$router.resolve({name: 'Welcome'})},
        {name: this.$i18n.t('agreements'), icon: 'agreement-icon', activeIcon: 'active-agreement-icon', link: this.agreementsLink, baseLink: this.$router.resolve({name: 'Agreements'})},
        // {name: this.$i18n.t('menu'), icon: 'menu-icon', activeIcon: 'active-menu-icon', link: '/menu'},
        {name: this.$i18n.t('support'), icon: 'chat-icon', activeIcon: 'active-chat-icon', link: this.chatNavigationLink, baseLink: this.$router.resolve({name: 'chat.navigation'}) },
        // {name: 'HOMEBANK', icon: 'home-bank-icon', activeIcon: 'home-bank-icon', link: 'https://homebank.kz/'},
      ]
    },
    welcomeLink() {
      let route =  this.$router.resolve({name: 'Welcome'});
      let baseUrl = this.$store.getters.appUrl + route.href;

      if (!this.authStatus && route.resolved.meta?.requiresAuth && this.$store.getters.isNewVersionHB) {
        return `${this.$store.getters.hbUrl}${baseUrl}`
      }
      return baseUrl
    },
    chatNavigationLink() {
      let route =  this.$router.resolve({name: 'chat.navigation'});
      let baseUrl = this.$store.getters.appUrl + route.href;

      if (!this.authStatus && route.resolved.meta?.requiresAuth && this.$store.getters.isNewVersionHB) {
        return `${this.$store.getters.hbUrl}${baseUrl}`
      }
      return baseUrl
    },
    agreementsLink() {
      let route =  this.$router.resolve({name: 'Agreements'});
      let baseUrl = this.$store.getters.appUrl + route.href;

      if (!this.authStatus && route.resolved.meta?.requiresAuth && this.$store.getters.isNewVersionHB) {
        return `${this.$store.getters.hbUrl}${baseUrl}`
      }
      return baseUrl
    },
  }
}
</script>

<style lang="scss">
@import "../sass/variables";

.navigation {
  background-color: #FFFFFF;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
  height: $navigation-high;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 0 10px 15px;
}

.nav-inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .nav-inner__link {
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #6E7078;
    padding: 10px 0;
    &.active {
      color: #2AA65C;
    }
  }
}
</style>
