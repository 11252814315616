<template>
  <svg width="34" height="11" viewBox="0 0 34 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4932 10.7885L13.7289 0.443356L16.4061 0.447782L14.1748 10.7923L11.4932 10.7885ZM29.0876 3.26805L29.8828 7.15618L27.6597 7.15027L29.0876 3.26805ZM26.8861 9.26858L30.3139 9.27507L30.6317 10.823L33.1147 10.8268L30.9742 0.473451L28.6901 0.468435C28.1726 0.468435 27.74 0.76616 27.5464 1.22676L23.5122 10.8106L26.324 10.8162L26.8861 9.26858ZM18.99 10.9632C21.8014 10.9688 23.6444 9.58076 23.6577 7.43207C23.6681 5.79621 22.3154 5.083 21.2335 4.51258C20.5074 4.12972 19.9032 3.81118 19.9151 3.32116C19.9227 2.95115 20.2783 2.55605 21.0508 2.45632C21.4373 2.40852 22.4937 2.36986 23.6919 2.924L24.1643 0.729573C23.522 0.494993 22.6908 0.269855 21.6601 0.266609C19.0151 0.262774 17.1488 1.66642 17.1287 3.68144C17.1104 5.17095 18.4553 6.00452 19.4677 6.50053C20.5102 7.01071 20.8616 7.33735 20.854 7.79087C20.8469 8.48605 20.0204 8.78879 19.2511 8.80089C17.9448 8.81634 17.1713 8.45436 16.5593 8.16792C16.5402 8.15901 16.5213 8.15017 16.5026 8.14141L16.0122 10.4087C16.6404 10.6967 17.7908 10.9475 18.99 10.9632ZM6.96715 7.47308L9.76883 0.433914L12.5823 0.439226L8.22562 10.7811L5.39325 10.7755L3.27259 2.51238C3.14542 2.00132 3.0324 1.81661 2.63908 1.60062C1.99819 1.25126 0.933578 0.922254 -0.000610352 0.715115L0.0643048 0.41621L4.61958 0.426243C5.19821 0.426243 5.72078 0.813078 5.85356 1.48229L6.96715 7.47308Z" fill="#1A1F71"/>
  </svg>
</template>

<script>
export default {
  name: "VisaIcon"
}
</script>

<style scoped>

</style>
