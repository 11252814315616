<template>
  <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.3203 8.5C49.3639 6.88814 45.0357 6 40.5 6C21.7223 6 6.5 21.2223 6.5 40C6.5 58.7777 21.7223 74 40.5 74C59.2777 74 74.5 58.7777 74.5 40C74.5 37.9527 74.319 35.9477 73.9723 34" stroke="#EAF6EF" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M75.5 10L37.7857 48L27.5 37.6364" stroke="#EAF6EF" stroke-width="5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "SuccessIcon"
}
</script>

<style scoped>

</style>