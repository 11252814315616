<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1V9H17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M1 9H9V17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "PlusIcon"
}
</script>

<style scoped>

</style>
