<template>
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.60435 13.4442L6.60435 8.92056C6.26661 8.75284 5.88597 8.65854 5.48329 8.65854C4.08943 8.65854 2.95947 9.78849 2.95947 11.1824C2.95947 12.5762 4.08943 13.7062 5.48329 13.7062C5.88597 13.7062 6.26661 13.6119 6.60435 13.4442Z" fill="#FCB016"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40029 5.62212C3.40029 4.92519 2.83531 4.36022 2.13838 4.36022C1.44144 4.36022 0.876465 4.92519 0.876465 5.62212C0.876465 6.31906 1.44144 6.88403 2.13838 6.88403C2.83531 6.88403 3.40029 6.31906 3.40029 5.62212Z" fill="#FCB016"/>
    <path d="M7.59668 14.4402V7.72024V1.00024" stroke="#2AA65C" stroke-width="2" stroke-linecap="round"/>
    <path d="M20.5482 12.7602C19.5513 10.2975 17.1369 8.56024 14.3167 8.56024C10.6053 8.56024 7.59668 11.5689 7.59668 15.2802C7.59668 18.9916 10.6053 22.0002 14.3167 22.0002C17.1369 22.0002 19.5513 20.263 20.5482 17.8002" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "BonusIcon"
}
</script>

<style scoped>

</style>