<template>
  <div class="rating-stars">
    <div
        class="star"
        v-for="(star, index) in stars"
        :key="index"
        @click="rate(index)"
        :class="[value>=star && value!== null ? 'active' : '']"
    ></div>
  </div>
</template>

<script>
export default {
  name: "RatingStars",
  data() {
    return {
      stars: [1,2,3,4,5],
      value: null
    }
  },
  methods: {
    rate(star) {
      this.value = star+1;
    }
  },
  watch: {
    value(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.rating-stars {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-column-gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  input {
    position:absolute;
    top:-9999px;
  }
  .star {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    &:after {
      content: url("/img/rating-star.svg");
    }
  }
  .star.active {
    &:after {
      content: url("/img/active-rating-star.svg");
    }
  }
}
</style>
