<template>
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 22V11.5256L11.9427 2L22 11.5256V22H2Z" stroke="#2AA65C" stroke-width="2" stroke-linejoin="round"/>
    <path opacity="0.496419" d="M8.78076 21.9996V14.4868H15.2847V21.9996" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
