<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.25 11H15.25C14.0074 11 13 11.985 13 13.2V30.8C13 32.015 14.0074 33 15.25 33H28.75C29.9926 33 31 32.015 31 30.8V17.6L24.25 11Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 11V18H31" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M27 25H17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M20 21H17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M27 29H17" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="#F1F2F6"/>
  </svg>
</template>

<script>
export default {
  name: "ToWithdraw"
}
</script>