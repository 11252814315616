<template>
  <section class="header container">
    <div class="header-inner">
      <a href="/" class="logo">
        <img src="/img/logo.svg" alt="" class="logo__image">
      </a>
      <div class="header-navigations">
        <a href="tel:797" class="d-flex align-items-center" style="margin-right: 20px;">
          <svg data-v-cf4df19c="" data-v-98f27cc4="" data-v-d3b0f676="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-cf4df19c="" d="M14 6C16.0233 6.39475 17.6052 7.97672 18 10" stroke="#FCB016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-cf4df19c="" d="M14 2C18.2058 2.46782 21.5275 5.78946 22 10" stroke="#FCB016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path data-v-cf4df19c="" fill-rule="evenodd" clip-rule="evenodd" d="M20.9994 19.1862C21.0015 19.6948 20.7886 20.1808 20.413 20.5247C20.0375 20.8685 19.5341 21.0384 19.0265 20.9927C16.2415 20.6907 13.5664 19.7409 11.216 18.2197C9.0293 16.8329 7.17534 14.9826 5.78581 12.8003C4.25628 10.4439 3.30442 7.76107 3.00735 4.96916C2.96162 4.46413 3.13068 3.96317 3.47323 3.58866C3.81578 3.21415 4.30028 3.00057 4.80837 3.00009H7.52347C8.43201 2.99116 9.20639 3.65581 9.33354 4.55366C9.44814 5.42084 9.66067 6.27229 9.96707 7.09177C10.2157 7.75193 10.0567 8.49614 9.5598 8.99761L8.41041 10.1447C9.69877 12.406 11.5748 14.2784 13.8406 15.5642L14.99 14.4171C15.4925 13.9212 16.2382 13.7625 16.8996 14.0106C17.7207 14.3164 18.5739 14.5285 19.4428 14.6429C20.3528 14.771 21.0222 15.5595 20.9994 16.4765V19.1862Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <span style="color: #03993f; font-weight: bold; font-size: 18px; margin-left: 5px;">797</span>
        </a>
        <a href="/notifications" class="d-flex align-items-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9976 2C6.47717 2 2 6.47717 2 11.9976C2 13.7363 2.43468 15.475 3.30403 16.9963L2 21.9082L6.91185 20.6042C11.6498 23.4296 17.7788 21.8213 20.6042 17.0833C23.4296 12.3453 21.8213 6.21636 17.0833 3.39097C15.5184 2.47814 13.7797 2 11.9976 2Z" stroke="#2AA65C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path opacity="0.5" d="M16.3444 11.5629C16.3444 13.9536 14.3884 15.9097 11.9976 15.9097C9.60692 15.9097 7.65088 13.9536 7.65088 11.5629" stroke="#2AA65C" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </div>

    </div>
    <div class="header-title-wrap" :class="{'center': centerTitle}">
      <a href="/" class="back-btn" v-if="centerTitle">
        <icon icon-name="BackArrowIcon"></icon>
      </a>
      <div class="header-title" v-if="headerTitle">{{ headerTitle }}</div>
      <div></div>
    </div>
  
    
  </section>
</template>

<script>
export default {
  name: "TheHeader",
  props: {
    headerTitle: {
      type: String
    },
    centerTitle:{
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
@import "src/sass/variables";
.header {
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
  }
  .header-title {
    background-color: #FFFFFF;
    padding: 0px 20px 16px;
    font-weight: 800;
    font-size: 28px;
    height: $header-title;
  }
  .header-navigations {
    display: flex;
  }
}
.logo {
  max-width: 110px;
  height: 30px;
  .logo__image {
    width: 100%;
    height: 100%;
  }
}

.header-title-wrap{
  display: flex;
  &.center{
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 18px;
    padding-left: 18px;
    .back-btn{
      height: 15px;
      
    }
    .header-title{
      padding: 0px;
      height: max-content;

    }
  }
}
</style>
