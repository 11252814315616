<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0004 22C14.7604 22 17.2603 20.88 19.0703 19.07C20.8753 17.26 21.9953 14.76 21.9953 11.995C21.9953 9.22999 20.8753 6.72999 19.0653 4.91999C17.2554 3.10999 14.7554 1.98999 11.9904 1.98999C9.22535 1.98999 6.72535 3.10499 4.91535 4.91499C3.10535 6.71999 1.98535 9.21999 1.98535 11.985C1.98535 14.745 3.10035 17.245 4.91035 19.055C6.71535 20.86 9.21535 21.98 11.9804 21.98L12.0004 22Z" fill="#2AA65C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.5C12.69 5.5 13.25 6.055 13.25 6.75C13.25 7.44 12.69 8 12 8C11.305 8 10.75 7.44 10.75 6.75C10.75 6.055 11.305 5.5 12 5.5Z" fill="white"/>
    <path d="M12.25 17V10H11.75H11.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 17H14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ReadInfoIcon"
}
</script>

<style scoped>

</style>