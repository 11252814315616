<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
		<path d="M7.91667 1.6665H2.08333C1.6231 1.6665 1.25 2.0396 1.25 2.49984V8.33317C1.25 8.79341 1.6231 9.1665 2.08333 9.1665H7.91667C8.3769 9.1665 8.75 8.79341 8.75 8.33317V2.49984C8.75 2.0396 8.3769 1.6665 7.91667 1.6665Z" stroke="#FF005D" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M6.6665 0.833496V2.50016" stroke="#FF005D" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M3.3335 0.833496V2.50016" stroke="#FF005D" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M1.25 4.1665H8.75" stroke="#FF005D" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>
<script>
	export default {
	  name: "CalendarSvg"
	}
</script>