<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" fill="white"/>
    <path d="M14 30V12C14 10.8954 14.8954 10 16 10H28C29.1046 10 30 10.8954 30 12V32C30 33.1046 29.1046 34 28 34H27M14 30H9V31C9 32.6569 10.3431 34 12 34H27M14 30H24V31C24 32.6569 25.3431 34 27 34V34" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M22 20C22 20 25 18.6 25 16.5V14.05L22 13L19 14.05V16.5C19 18.6 22 20 22 20Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 27H26" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 24H26" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="#F1F2F6"/>
  </svg>
</template>

<script>
export default {
  name: "SwitchTariff"
}
</script>