<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" fill="white"/>
    <rect x="9" y="13" width="26" height="18" rx="2" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15 23C15.5523 23 16 22.5523 16 22C16 21.4477 15.5523 21 15 21C14.4477 21 14 21.4477 14 22C14 22.5523 14.4477 23 15 23Z" stroke="#2AA65C" stroke-width="2"/>
    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M22 23C22.5523 23 23 22.5523 23 22C23 21.4477 22.5523 21 22 21C21.4477 21 21 21.4477 21 22C21 22.5523 21.4477 23 22 23Z" stroke="#2AA65C" stroke-width="2"/>
    <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M29 23C29.5523 23 30 22.5523 30 22C30 21.4477 29.5523 21 29 21C28.4477 21 28 21.4477 28 22C28 22.5523 28.4477 23 29 23Z" stroke="#2AA65C" stroke-width="2"/>
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="#F1F2F6"/>
  </svg>
</template>

<script>
export default {
  name: "ExtendPolicy"
}
</script>