<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.84619 13.9072C4.63037 14.1313 4.51416 14.397 4.51416 14.7124C4.51416 15.3516 5.02881 15.8745 5.66797 15.8745C5.9834 15.8745 6.28223 15.7417 6.50635 15.5093L12.0015 9.86475L17.48 15.5093C17.7124 15.7417 18.0112 15.8745 18.3184 15.8745C18.9575 15.8745 19.4722 15.3516 19.4722 14.7124C19.4722 14.3887 19.3643 14.123 19.1484 13.9072L12.9229 7.54053C12.6489 7.2583 12.3501 7.11719 11.9932 7.11719C11.6445 7.11719 11.3457 7.25 11.0635 7.54053L4.84619 13.9072Z" fill="#081221"/>
  </svg>
</template>

<script>
export default {
  name: "AccordionArrowIcon"
}
</script>

<style scoped>

</style>