<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#FD2D39"/>
    <path d="M5.5 10.5L10.5 5.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 5.5L10.5 10.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "RemoveIcon"
}
</script>

<style scoped>

</style>