<template>
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00403 8C7.20553 8 7.40702 7.9182 7.54404 7.76278L13.7824 1.27607C13.9194 1.13701 14 0.957055 14 0.752556C14 0.327198 13.6857 0 13.2666 0C13.0651 0 12.8797 0.0817996 12.7427 0.212679L7.00403 6.16769L1.25734 0.212679C1.12838 0.0817996 0.943005 0 0.733448 0C0.314335 0 0 0.327198 0 0.752556C0 0.957055 0.0805987 1.13701 0.217617 1.28425L6.45596 7.76278C6.6091 7.9182 6.79447 8 7.00403 8Z" fill="#9AA3B2"/>
  </svg>
</template>

<script>
export default {
  name: "SelectArrowIcon"
}
</script>

<style scoped>

</style>