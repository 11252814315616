<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11.9072L12.49 20.3791C11.4475 21.4169 10.0335 22 8.55912 22C7.08475 22 5.67077 21.4169 4.62823 20.3791C3.58569 19.3412 3 17.9335 3 16.4658C3 14.998 3.58569 13.5904 4.62823 12.5525L13.1382 4.08062C13.8332 3.38871 14.7759 3 15.7588 3C16.7417 3 17.6844 3.38871 18.3794 4.08062C19.0744 4.77254 19.4649 5.71097 19.4649 6.68948C19.4649 7.66799 19.0744 8.60643 18.3794 9.29834L9.86016 17.7702C9.51265 18.1162 9.04132 18.3105 8.54986 18.3105C8.0584 18.3105 7.58708 18.1162 7.23956 17.7702C6.89205 17.4243 6.69682 16.955 6.69682 16.4658C6.69682 15.9765 6.89205 15.5073 7.23956 15.1614L15.1013 7.344" stroke="#9AA3B2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ClipIcon"
}
</script>

<style scoped>

</style>