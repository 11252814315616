<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["accessToken", "authUser"]),
  },
  async created() {
    if (!this.authUser && this.accessToken) await this.getAuthUser();
  },
  methods: {
    ...mapActions(["getAuthUser"]),
  },
};


// focus events don't bubble, must use capture phase
document.body.addEventListener("focus", event => {
  const target = event.target;

  const {tagName, type} = target

  if((tagName === "INPUT" && !['radio', 'checkbox'].includes(type)) || tagName === "TEXTAREA") {
    document.body.classList.add("keyboard");
  }
}, true);

document.body.addEventListener("blur", () => {
  document.body.classList.remove("keyboard");
}, true);
</script>

<style>
@media (min-aspect-ratio: 1/2) {
  body {
    width: 50vh;
    margin: auto;
  }
}

body.keyboard .final-amount-wrapper{
  position: inherit;
  /*width: 100%;*/
  /*margin-top: 20px;*/
}

.body.keyboard .content {
  padding-bottom: 50vh;
}

</style>

