<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M11.9999 4L11.9999 5M8.17369 18.5C7.62691 19.5 8.42485 21 9.8136 21C11.2022 21 12 20.5 12 19.5L12 11.1395" stroke="#FCB016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19 12C19 12 18.0502 11 17 11C16.2923 11 15.5391 12 15 12C13.7442 12 13.8709 11 12 11C10.4779 11 10.1486 12 9 12C8.38587 12 7.6129 11 7 11C5.84773 11 5 12 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "UmbrellaIcon"
}
</script>

<style scoped>

</style>