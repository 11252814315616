<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
		<path d="M1 7V1.5C1 1.5 1 0.5 4.5 0.5C8 0.5 8 1.5 8 1.5V7C8 7 7 8 4.5 8C2 8 1 7 1 7Z" stroke="#11D996" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M1 1.5C1 1.5 1.5 2.5 4.5 2.5C7.5 2.5 8 1.5 8 1.5" stroke="#11D996"/>
		<path d="M1 3.33337C1 3.33337 1.5 4.33337 4.5 4.33337C7.5 4.33337 8 3.33337 8 3.33337" stroke="#11D996"/>
		<path d="M1 5.16675C1 5.16675 1.5 6.16675 4.5 6.16675C7.5 6.16675 8 5.16675 8 5.16675" stroke="#11D996"/>
	</svg>
</template>
<script>
export default {
  name: "Coins"
}
</script>