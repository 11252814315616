<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C15.4706 20 20 15.4706 20 10C20 4.53922 15.4608 0 9.9902 0C4.52941 0 0 4.53922 0 10C0 15.4706 4.53922 20 10 20ZM6.48039 14.3529C6.02941 14.3529 5.65686 13.9804 5.65686 13.5196C5.65686 13.2941 5.7451 13.098 5.90196 12.951L8.83333 10.0098L5.90196 7.06863C5.7451 6.93137 5.65686 6.72549 5.65686 6.5C5.65686 6.04902 6.02941 5.68628 6.48039 5.68628C6.71569 5.68628 6.90196 5.77451 7.04902 5.93137L9.9902 8.86275L12.951 5.92157C13.1176 5.7451 13.3039 5.66667 13.5196 5.66667C13.9804 5.66667 14.3431 6.03922 14.3431 6.4902C14.3431 6.71569 14.2745 6.90196 14.098 7.05882L11.1569 10.0098L14.0882 12.9314C14.2451 13.0882 14.3333 13.2843 14.3333 13.5196C14.3333 13.9804 13.9706 14.3529 13.5098 14.3529C13.2745 14.3529 13.0686 14.2549 12.9216 14.1078L9.9902 11.1667L7.06863 14.1078C6.92157 14.2647 6.71569 14.3529 6.48039 14.3529Z" fill="#9AA3B2"/>
  </svg>
</template>

<script>
export default {
  name: "CloseButtonIcon"
}
</script>

<style scoped>

</style>