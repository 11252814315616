<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" fill="white"/>
    <rect x="9" y="13" width="26" height="18" rx="2" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <g opacity="0.5">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 20L35 19.9981Z" fill="#2AA65C"/>
      <path d="M9 20L35 19.9981" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <path d="M14.5 27C15.3284 27 16 26.3284 16 25.5C16 24.6716 15.3284 24 14.5 24C13.6716 24 13 24.6716 13 25.5C13 26.3284 13.6716 27 14.5 27Z" fill="#2AA65C"/>
    <rect x="0.5" y="0.5" width="43" height="43" rx="11.5" stroke="#F1F2F6"/>
  </svg>

</template>

<script>
export default {
  name: "ChangeCard"
}
</script>