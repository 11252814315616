<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 15.3333C22 16.5606 21.0051 17.5556 19.7778 17.5556H6.44444L2 22V4.22222C2 2.99492 2.99492 2 4.22222 2H19.7778C21.0051 2 22 2.99492 22 4.22222V15.3333Z" stroke="#2AA65C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path opacity="0.5" d="M7 6.5H16" stroke="#2AA65C" stroke-width="2" stroke-linecap="round"/>
    <path opacity="0.5" d="M7 11.5H13" stroke="#2AA65C" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "ActiveChatIcon"
}
</script>

<style scoped>

</style>
